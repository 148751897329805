import React from "react";
import { techAndTools } from "../aboutMeData";

const TechAndToolsTab = () => {
  return (
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills">
      {techAndTools.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <img
            src={skill.src}
            alt={skill.alt}
            width={skill.width}
            loading="lazy"
          />
        </div>
      ))}
    </div>
  );
};

export default TechAndToolsTab;
