import React from "react";
import { otherSkillsData } from "../data/otherSkillsData";

const OtherSkillsTab = () => {
  return (
    <div
      className="resume-screen-container programming-skills-container"
      key="soft-skills">
      {otherSkillsData.map((skill, index) => (
        <div className="skill-parent1" key={index}>
          <div className="heading-bullet"></div>
          <p>{skill}</p>
        </div>
      ))}
    </div>
  );
};

export default OtherSkillsTab;
