import React from "react";
import "./Footer.css";
// import ScrollService from "../../utilities/ScrollService";

export default function Footer() {
  return (
    <div className="footer-container">
      <div className="footer-parent">
        {/* <div className="main-message">Portfolio</div> */}
        <p>@2022 Gi Diaz, Full Stack Software Engineer</p>
      </div>
    </div>
  );
}

/*ScrollService.scrollHandler.scrollToHome() */
