import React from "react";

const InterestsTab = () => {
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  return (
    <div
      className="resume-screen-container programming-skills-container"
      key="interests">
      <ResumeHeading
        heading="Sports"
        description="Apart from being a tech enthusiast , I also love to test my physical resistance by participating in obstacle races like Spartan, RuggedManiac, TD Five Boro Bike Tour and others."
      />
      <ResumeHeading
        heading="Art"
        description="Depending on the Season, I go deep into Art, such as painting, figure drawing, cross stitching, building puzzles, and others. These are my hobbies. What about yours?"
      />
    </div>
  );
};

export default InterestsTab;
