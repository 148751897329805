export const techAndTools = [
  {
    align: "left",
    alt: "Visual Studio Code",
    width: "40px",
    src: "https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/visual-studio-code/visual-studio-code.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/react-native.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/redux.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/nodejs.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/git.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color-glass/48/000000/github.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/heroku.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/postgreesql.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/webpack.png",
  },
  {
    align: "left",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/mongodb.png",
  },
  {
    align: "left",
    src: "https://playwright.dev/img/playwright-logo.svg",
    width: "48px",
  },
  {
    width: "40",
    alt: "bubble.io",
    src: "https://s3.amazonaws.com/appforest_uf/f1530294839424x143528842134401200/Icon-no-clearspace.png",
  },
  {
    align: "left",
    alt: "SQL",
    width: "40px",
    src: "https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/sql/sql.png",
  },
  {
    align: "left",
    alt: "Terminal",
    width: "40px",
    src: "https://raw.githubusercontent.com/github/explore/80688e429a7d4ef2fca1e82350fe8e3517d3494d/topics/terminal/terminal.png",
  },
  {
    align: "left",
    alt: "Python",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/python.png",
  },
  {
    align: "left",
    alt: "TypeScript",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/typescript.png",
  },
  {
    align: "left",
    alt: "ESLint",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/eslint.png",
  },
  {
    align: "left",
    alt: "Firebase",
    width: "40px",
    src: "https://img.icons8.com/color/48/000000/firebase.png",
  },
  {
    align: "left",
    alt: "AWS",
    width: "40px",
    src: "https://img.icons8.com/?size=100&id=33039&format=png&color=000000",
  },
  {
    align: "left",
    alt: "JIRA",
    width: "40px",
    src: "https://img.icons8.com/?size=100&id=oROcPah5ues6&format=png&color=000000",
  },
  {
    align: "left",
    alt: "GraphQL",
    width: "40px",
    src: "https://img.icons8.com/?size=100&id=KRA1PoZgRrca&format=png&color=000000",
  },
  {
    align: "left",
    alt: "MaterialUI",
    width: "40px",
    src: "https://react.semantic-ui.com/logo.png",
  },
  {
    align: "left",
    alt: "MaterialUI",
    width: "30px",
    src: "https://github.com/YoshiTunaiga/yoshitunaiga/assets/85481752/cf660d2f-5d04-4994-ae33-f4d3f3d785f8",
  },
];
