import React from "react";
import ScrollService from "../../../utilities/ScrollService";
import "./Profile.css";

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="colz">
            <div className="colz-icon">
              <a
                className="icon-light-color"
                href="https://www.linkedin.com/in/gisseldiazf/">
                <i className="fa fa-linkedin-square fa-2x"></i>
              </a>
              <a
                className="icon-light-color"
                href="https://github.com/YoshiTunaiga">
                <i className="fa fa-github fa-2x"></i>
              </a>
            </div>
          </div>

          <div className="profile-details-name">
            <span className="primary-text">
              Hello, I'm <span className="highlighted-text">Gi</span> Diaz
            </span>
          </div>
          <div className="profile-details-role">
            <span className="profile-text">
              <div className="tagline">
                <h1
                  style={{
                    fontFamily: "Arial Black",
                    fontSize: "48px",
                    padding: 0,
                    margin: 0,
                  }}>
                  Full Stack Software Engineer
                </h1>
              </div>
              <div className="tagline">
                <p
                  style={{
                    fontFamily: "Arial Black",
                    marginTop: 5,
                    opacity: 0.8,
                  }}>
                  Innovating Healthcare Solutions for Better Patient Outcomes,
                  Prevention Navigation, Scalable Web Applications, Agile
                  Development, JavaScript
                </p>
              </div>
            </span>
          </div>
          <div className="profile-options">
            <button
              className="btn primary-btn"
              onClick={() => ScrollService.scrollHandler.scrollToHireMe()}>
              Contact Me
            </button>
            {/* download="Gissel gidiaz.pdf" */}
            {/* <a href="gidiaz.pdf" target="_blank"> */}
            {/* <button className="btn highlighted-btn">Get Resume</button> */}
            {/* </a> */}
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
      <div className="profile-sub-section">
        <div>
          <p className="subheading-color">Location</p>
          <p>Kissimmee, FL</p>
        </div>
        <div>
          <p className="subheading-color">Tech Experience</p>
          <p>2+ Years</p>
        </div>
        <div>
          <p className="subheading-color">Customer Services</p>
          <p>12+ Years</p>
        </div>
        <div>
          <p className="subheading-color">Language</p>
          <p>Billingual</p>
        </div>
      </div>
    </div>
  );
}
