import React, { useEffect, useState } from "react";
import Typical from "react-typical";
import load1 from "../../../src/assets/Home/load2.gif";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animation";
import Footer from "../../PortfolioContainer/footer/Footer";
import "./ContactMe.css";

export default function ContactMe({ id = "" }) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== id) return;
    Animations.animations.fadeInScreen(id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [bool, setBool] = useState(false);

  /* ------- HANDLE FUNCTIONS -------- */
  const handleName = (e) => {
    setName(e.target.value);
  };
  const handleEmail = (e) => {
    setEmail(e.target.value);
  };
  const handleMessage = (e) => {
    setMessage(e.target.value);
  };
  /* -------END OF HANDLE FUNCTIONS ------ -- */

  /* --------RESET FORM ------------ */
  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
  };
  /* --------END OF RESET FORM ------------ */

  /* -------- SUBMIT FORM FUNCTION ------------- */
  // const submitForm = async (e) => {
  // e.preventDefault();
  // try {
  //   let data = {
  //     name,
  //     email,
  //     message,
  //   };

  //   setBool(true);

  // const res = await axios.post(`/contact`, data);
  // // If form has not been fully completed, send error message
  // if (name.length === 0 || email.length === 0 || message.length === 0) {
  //   setBanner(res.data.msg);
  //   toast.error(res.data.msg);
  //   setBool(false);
  // else, send message of success form completion
  // } else if (res.status === 200) {
  // setBanner(res.data.msg);

  // setBool(false);

  // setName("");
  // setEmail("");
  // setMessage("");
  // toast.success("Form has been submitted. Thank You");
  // }
  // } catch (error) {
  //   console.log(error);
  // }
  // };
  /* ------END of SUBMIT FORM FUNCTION ------------- */

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div className="main-container screen-container fade-in" id={id}>
      <ScreenHeading subHeading={"Lets Keep In Touch"} title={"Contact Me"} />
      <div className="central-form">
        <div className="back-form">
          <div className="img-back">
            <div className="col">
              <h2 className="title">
                <Typical
                  className="icon-light-color"
                  loop={Infinity}
                  steps={[
                    "Get In Touch 📧",
                    1000,
                    "Let's Connect...☎️",
                    1000,
                    "Networking....📡",
                    1000,
                  ]}
                />
              </h2>{" "}
            </div>
            <h4 style={{ textAlign: "center", textWrap: "wrap" }}>
              Send me a message by filling out the form, I will reach back as
              soon as possible!
            </h4>
            <h4>We can also connect through:</h4>
            <div className="icons-container">
              <a
                className="icon-light-color"
                href="https://www.linkedin.com/in/gisseldiazf/">
                <i className="fa fa-linkedin-square fa-2x"></i>
              </a>
              <a
                className="icon-light-color"
                href="https://github.com/YoshiTunaiga">
                <i className="fa fa-github fa-2x"></i>
              </a>
            </div>
          </div>
          <form
            id="myForm"
            target="_blank"
            action="https://formsubmit.co/21e967bacac70ca9dc5e1444e0beeb8c"
            method="POST"
            // onSubmit={submitForm}
          >
            {/* single item */}
            <label htmlFor="name">Name</label>
            <input
              type="text"
              name="name"
              required
              onChange={handleName}
              value={name}
            />
            {/* end single item */}

            {/* single item */}
            <label htmlFor="email">Email</label>
            <input
              type="email"
              name="email"
              onChange={handleEmail}
              value={email}
            />
            {/* end single item */}

            {/* single item */}
            <label htmlFor="message">Message</label>
            <textarea
              type="text"
              name="message"
              onChange={handleMessage}
              value={message}
            />
            {/* end single item */}

            <div className="send-btn">
              <button type="submit">
                Send
                <i className="fa fa-paper-plane" />
                {bool ? (
                  <b className="load">
                    <img src={load1} alt="load not responding" loading="lazy" />
                  </b>
                ) : (
                  ""
                )}
              </button>
            </div>
            <div className="send-btn">
              <button type="reset" onClick={() => resetForm()}>
                Clear
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
