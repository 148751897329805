import Home from "../PortfolioContainer/Home/Home";
import AboutMe from "../PortfolioContainer/AboutMe/AboutMe";
import Resume from "../PortfolioContainer/Resume/Resume";
import Projects from "../PortfolioContainer/Projects/Projects";
import ContactMe from "../PortfolioContainer/ContactMe/ContactMe";

// Component Rendering: Screens for SPA
export const TOTAL_SCREENS = [
  {
    screen_name: "Home",
    component: Home,
  },
  {
    screen_name: "About Me",
    component: AboutMe,
  },
  {
    screen_name: "More Details",
    component: Resume,
  },
  // {
  //   screen_name: "Projects",
  //   component: Projects,
  // },
  {
    screen_name: "Contact Me",
    component: ContactMe,
  },
];

// titration to get the available screens index
export const GET_SCREEN_INDEX = (screen_name) => {
  // If the screen name doesn't exist, return -1 (not such screen on the array)
  if (!screen_name) return -1;

  // Loop thru the array of screens
  for (let i = 0; i < TOTAL_SCREENS.length; i++) {
    // if the screen does exist, return the screen
    if (TOTAL_SCREENS[i].screen_name === screen_name) return i;
  }

  return -1; // else return -1.
};
