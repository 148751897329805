export const volunteeringDetails = [
  {
    company: "Out In Tech",
    title: "Digial Corps Software Engineer",
    content: `Helped organizations around the world including Africa and Central America. Collaborated using WordPress to assist with features for their
    websites. Tackled many tickets of these organizations in one day!`,
    year: 2022,
  },
  {
    company: "Out In Tech",
    title: "Digial Corps Software Engineer",
    content: `Helped organizations around the world including Africa and Tennessee, US. Collaborated using WordPress to assist with features for their
    websites. Tackled Feedback Hub besides accessibilities from one of these
    organizations in one day!`,
    year: 2023,
  },
];
