import React, { useEffect, useState } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animation";
import VolunteeringTab from "./components/VolunteeringTab";
import OtherSkillsTab from "./components/OtherSkillsTab";
import InterestsTab from "./components/InterestsTab";
import TechAndToolsTab from "./components/TechAndToolsTab";
import "./Resume.css";

export default function Resume(props) {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  // /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Tech & Tools", logoSrc: "fa fa-laptop" },
    { label: "Other Skils", logoSrc: "fa fa-black-tie" },
    { label: "Interests", logoSrc: "fa fa-futbol-o" },
    {
      label: "Volunteer",
      logoSrc: "fa fa-yelp",
    },
  ];

  const resumeDetails = [
    <TechAndToolsTab key="tech&tools" />,
    <OtherSkillsTab key="other skills" />,
    <InterestsTab key="interests" />,
    <VolunteeringTab key="volunteer" />,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 360;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}>
        <i className={`${bullet.logoSrc}`} aria-hidden="true"></i>
        <span className="bullet-label"> - {bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal">
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}>
      <div className="resume-content">
        <ScreenHeading
          title={"More Details"}
          subHeading={"Know A Little Bit More About Me"}
        />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>
          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
}
