export const otherSkillsData = [
  "Responsive Web Design",
  "Agile Methodologies",
  "Scrum",
  "Kanban",
  "End-to-End Test",
  "Problem-solving",
  "Teamwork",
  "Communication",
  "Adaptability",
  "Attention to detail",
  "Passionate",
  "Proactive",
  "Multitasker",
];
