import React from "react";
import { TOTAL_SCREENS } from "../utilities/commonUtils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";

export default function PortfolioContainer() {
  // Get the button
  const scrollTopButton = document.getElementById("myBtn") || {
    style: { display: "none" },
  };

  // When the user scrolls down 20px from the top of the document, show the button
  window.onscroll = () => {
    scrollFunction();
  };

  const scrollFunction = () => {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      scrollTopButton.style.display = "block";
    } else {
      scrollTopButton.style.display = "none";
    }
  };

  // When the user clicks on the button, scroll to the top of the document
  const topFunction = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const mapAllScreens = () => {
    return TOTAL_SCREENS.map((screen) =>
      screen.component ? (
        <screen.component
          screenName={screen.screen_name}
          key={screen.screen_name}
          id={screen.screen_name}
        />
      ) : (
        <div key={screen.screen_name}></div>
      )
    );
  };

  return (
    <div className="portfolio-container">
      {mapAllScreens()}
      <FontAwesomeIcon
        id="myBtn"
        className="btn-scroll-up fa-4x"
        icon={faArrowCircleUp}
        onClick={topFunction}
      />
    </div>
  );
}
