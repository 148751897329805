import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animation";
import "./AboutMe.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  //this line can be sent to a file for reusable codes.
  const SCREEN_CONSTSANTS = {
    description: `Throughout my career, I've been dedicated to serving others, first as
    an EMT and Community Health Educator, accumulating 13 years of
    invaluable customer service experience. Alongside this, my passion for
    technology has always been evident. Transitioning into a Software
    Engineer role, I've honed my coding skills through the Grace Hopper
    Program.
          `,
    descriptionTwo: `As a Software Engineer, I bring a proven record of delivering results,
          backed by 15 years of customer service expertise and a profound
          commitment to healthcare. With over 2 years of experience integrating
          innovative methodologies and staying updated on industry advancements,
          I've contributed to pioneering software solutions that tangibly
          improve patient outcomes. My unwavering dedication extends beyond
          work, evident in my participation in Spartan obstacle races.`,
    descriptionThree: `I'm enthusiastic about leveraging technology to enhance lives and
    communities further while continuously expanding my knowledge in this
    dynamic field.`,
    highlights: {
      bullets: [
        "Full Stack web and mobile development",
        "React and React Native",
        "JavaScript and TypeScript",
        "Redux for State Management, Thunk about it",
        "React  Hooks",
      ],
      heading: "Here are a Few Highlights:",
    },
  };
  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="about-me-container screen-container fade-in"
      id={props.id || ""}>
      <div className="about-me-parent">
        <ScreenHeading title={"About Me"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile responsive-profile"></div>
          <div className="about-me-details">
            <p className="about-me-description">
              {SCREEN_CONSTSANTS.description}
            </p>
            <p className="about-me-description">
              {SCREEN_CONSTSANTS.descriptionTwo}
            </p>
            <p className="about-me-description">
              {SCREEN_CONSTSANTS.descriptionThree}
            </p>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-me-options">
              <button
                className="btn primary-btn"
                onClick={() => ScrollService.scrollHandler.scrollToHireMe()}>
                Contact Me
              </button>
              {/* <a href="gidiaz.pdf" target="_blank"> */}
              {/* <button className="btn highlighted-btn">Get Resume</button> */}
              {/* </a> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
